import 'bootstrap'
import './assets/scss/style.scss'
window.addEventListener('load', function () {
  let registerForm = document.querySelector('[name="register"]')
  registerForm && document.getElementById('register_passwordAgain').addEventListener('input', function (event) {
    if (document.getElementById('register_password').value !== event.target.value) {
      event.target.classList.add('is-invalid')
    } else {
      event.target.classList.remove('is-invalid')
    }
  }) && registerForm.addEventListener('submit', function (event) {
    if([...registerForm.getElementsByTagName('INPUT')].filter(function (element) {
      if (element.classList.contains('is-invalid')) {
        return element
      }
      return false
    }).length > 0) {
      event.preventDefault()
      event.stopImmediatePropagation()
      event.stopPropagation()
    }
  })
})
let intersectionObserver = new IntersectionObserver(function (targets) {
  targets.map(function (entry) {
    if (entry.intersectionRatio === 1)
      entry.target.classList.add('fill')
    if (entry.intersectionRatio < 0.9)
      entry.target.classList.remove('fill')
  })
}, {
  threshold: 1
})

intersectionObserver.observe(document.getElementById('list'))

let lastScroll = 0
function scrollsmooth (scrollElement) {
  let scrollTo = scrollElement.getBoundingClientRect().top
  if (scrollTo > 0 && lastScroll !== document.scrollingElement.scrollTop) {
    lastScroll = document.scrollingElement.scrollTop
    window.scrollBy(0, 50)
    requestAnimationFrame(function () {
      scrollsmooth(scrollElement)
    })
  }
}

document.querySelectorAll('a[data-toggle]').forEach(function (element) {
  if (element instanceof HTMLElement) {
    element.addEventListener('click', function (event) {
      let id = event.target.getAttribute('href')
      if (id) {
        setTimeout(function () {
          scrollsmooth(document.querySelector(id))
        }, 300)

      }
    }, { capture: true })
  }

})
